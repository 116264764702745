import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets

export default function Projects() {
  const gotoApp = () => {
    window.open("https://digital.pass-card.app", "_slef");
  };

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          {/* <HeaderInfo>
            <h1 className="font40 extraBold">Phyical card options</h1>
            <p className="font13">
              Purchase a one time card to accompany your Pass profile. The cards
              can be kept in a wallet or attached to a phone or a keychain. They
              are made of a durable plastic and are water resistant. The cards
              can are either NFC enabled, or QR code enabled, or both.
              <br />
            </p>
          </HeaderInfo> */}
          <div className="row textCenter">
            {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="NFC Card (8.5 by 5.4 cm)"
                text="Customizable with your own design, it comes with NFC and Qr code technologies, pre-loaded with your Pass profile."
                action={gotoShop}
              />
            </div> */}
            {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="NFC Card (25mm (stickable))"
                text="Customizable with your own design, it comes with NFC and Qr code technologies, pre-loaded with your Pass profile."
                action={gotoShop}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="NFC Card (25mm (non-stickable))"
                text="Customizable with your own design, it comes with NFC and Qr code technologies, pre-loaded with your Pass profile."
                action={gotoShop}
              />
            </div> */}
          </div>
          {/* <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="A sticker (36mm)"
                text="It comes withcQr code technology, pre-loaded with your Pass profile."
                action={gotoShop}
              />
            </div>
          </div> */}
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Shop physical cards" action={gotoShop} />
            </div>
          </div> */}
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src="/mocks/cards.png" alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">
                Augmented with physical accessories
              </h4>
              <h2 className="font40 extraBold">NFC powered physical cards.</h2>
              <p className="font12">
                The last physical medium you will ever buy for your business
                card. These NFC powered accessories augment your networking
                intercations by allowing you to share your contacts and/or links
                by just a tap or a QR code scan. They are made out of durable
                plastic and are built to last.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton title="Get yours" action={gotoApp} />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
