// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";

export default function Help() {
  return (
    <>
      <TopNavbar />
      <h2 className="title txt-black">Need help?</h2>
      <section
        class=""
        style={{
          marginTop: 40,
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 32,
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <small className="txt-black">
                Last Revised: <strong>March 04, 2024</strong>
              </small>
              <p>&nbsp;</p>
              <div class="textSection">
                <h5 className="txt-black">PLEASE CONTACT US</h5>
                <p className="txt-black">
                  Need help using pass?, write to us at:
                </p>
                <p className="txt-black">
                  <a href="mailto:support@pass-card.app">
                    <strong>support@pass-card.app</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
