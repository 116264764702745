import styled from "styled-components";
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  const contactUs = () => {
    window.location.href = "#contact";
  };
  const goToApp = () => {
    window.location.href = "https://digital.pass-card.app";
  };

  return (
    <Wrapper id="pricing">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font13">
              Please note that below prices are exclusive of any physical
              accessories purchase for users who need them.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="user"
                price="From $1.29"
                title="Personal"
                text="Renewed montlhy, or yearly."
                offers={[
                  {
                    name: "Digital cards on your mobile device",
                    cheked: true,
                  },
                  {
                    name: "Online and offline access",
                    cheked: true,
                  },
                  {
                    name: "Performance analytics",
                    cheked: true,
                  },
                ]}
                action={goToApp}
                actionTitle="Get started"
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="org"
                price="From $9.64"
                title="Organizations"
                text="Renewed yearly"
                offers={[
                  {
                    name: "All included from personal",
                    cheked: true,
                  },
                  {
                    name: "Branded digital cards and profiles",
                    cheked: true,
                  },
                  {
                    name: "Self-managed dashboards",
                    cheked: true,
                  },
                  {
                    name: "24/7 support",
                    cheked: true,
                  },
                ]}
                action={contactUs}
                actionTitle="Contact us"
              />
            </TableBox>
            {/* <TableBox>
              <PricingTable
                icon="org"
                price="$13.9 per user / Year"
                title="Organizations (< 100 people)"
                text="Renewed yearly"
                offers={[
                  {
                    name: "Branded digital cards and profiles",
                    cheked: true,
                  },
                  {
                    name: "All included from personal",
                    cheked: true,
                  },
                ]}
                action={goToApp}
                actionTitle="Contact us"
              />
            </TableBox> */}
            {/* <TableBox>
              <PricingTable
                icon="org"
                price="$8,49 / user / year"
                title="Organisation"
                text="Renewed yearly, this plan is ideal for instutions such as work places"
                offers={[
                  { name: "Digital cards on your mobile device", cheked: true },

                  {
                    name: "Physical card (on demand)",
                    cheked: true,
                  },
                ]}
                action={contactUs}
                actionTitle="Contact us"
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="card"
                price="$5.79 - $19,49 / card"
                title="Physical business card"
                text="A one-time purchase, the card is a technology enabled card"
                offers={[
                  {
                    name: "NFC & QR code",
                    cheked: true,
                  },
                  {
                    name: "Durable",
                    cheked: true,
                  },
                  {
                    name: "Portable",
                    cheked: true,
                  },
                ]}
                action={goTocards}
                actionTitle="See options"
              />
            </TableBox> */}
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  margin-right: 16px;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
