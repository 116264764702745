import styled from "styled-components";
import { Globe, Apple, SmartphoneIcon as Android } from "lucide-react";

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 324px;
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  margin-right: 14px;
  white-space: nowrap;
`;

const SegmentedButtonContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #006d5b;
  flex-grow: 1;
`;

const SegmentButton = styled.button`
  flex: 1;
  padding: 12px;
  background-color: white;
  color: "white";
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f5f5f5;
  }

  &:not(:last-child) {
    border-right: 1px dashed #006d5b;
  }
`;

const platforms = [
  { name: "Web", icon: Globe, url: "https://digital.pass-card.app" },
  {
    name: "iOS",
    icon: Apple,
    url: "https://apps.apple.com/us/app/pass-digital-business-card/id6475306518",
  },
  {
    name: "Android",
    icon: Android,
    url: "https://play.google.com/store/apps/details?id=rw.pass.app.fabriq",
  },
];

const openAppLink = (url) => {
  window.open(url, "_blank");
};

export default function DownloadButton({ onSelect }) {
  return (
    <Container>
      <Title>Get the app</Title>
      <SegmentedButtonContainer>
        {platforms.map(({ name, icon: Icon, url }) => (
          <SegmentButton
            key={name}
            $active={false}
            onClick={() => openAppLink(url)}
            aria-label={`Get the app on ${name}`}
          >
            <Icon size={24} color="#0b093b" />
          </SegmentButton>
        ))}
      </SegmentedButtonContainer>
    </Container>
  );
}
