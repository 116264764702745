import styled from "styled-components";
// Assets

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div
              className="col-xs-12 col-sm-12 col-md-6 col-lg-6  flex"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="font13">
                No boring contact form here. Please scan, or tap on, this QR
                code to see our contacts and the multiple ways you can reach us.{" "}
                <br />
                <p>We are looking forward to hearing from you!</p>
              </p>
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <QrCode>
                <a
                  href="https://profile.pass-card.app/TbaobSOMg2VKMSuEwoEjSb1qvcU2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/mocks/qr.png"
                    alt="Pass Team contacts"
                    style={{
                      height: 144,
                      borderRadius: 16,
                      boxShadow: "2px 2px rgba(0,0,0,0.15)",
                    }}
                    className=""
                  />
                </a>
              </QrCode>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #006d5b;
  background-color: #006d5b;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #006d5b;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
`;
const QrCode = styled.div`
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;
